<template>
  <div id="todo">
    <v-card>
      <v-card-title class="align-start">
        Check List
      </v-card-title>

      <v-tabs color="success">
        <v-tab class="text-xs">
          To do
        </v-tab>
        <v-tab class="text-xs">
          Done
        </v-tab>
        <v-tab-item>
          <v-card>
            <div
              v-if="!todoLoaded"
              class="mt-5"
            >
              <v-skeleton-loader
                type="list-item-avatar, divider, list-item-one-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar, divider, list-item-one-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-avatar, divider, list-item-one-line"
              ></v-skeleton-loader>
            </div>
            <v-card-text
              v-if="todoLoaded"
              class="ma-0 pa-0 mx-4"
            >
              <v-list class="pb-0">
                <v-list-item
                  v-for="(data,index) in toDoTasks"
                  :key="data.slug"
                  :class="`d-flex align-center px-0 ${index > 0 ? 'mt-0':''}`"
                  class="ma-0 pa-0 py-0 my-0"
                >
                  <v-avatar
                    size="30"
                    class="me-2"
                  >
                    <v-img :src="data.icon"></v-img>
                  </v-avatar>
                  <router-link :to="{ path: data.link }">
                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-1">
                        <v-list-item-subtitle class="text-sm">
                          {{ data.desc }}
                        </v-list-item-subtitle>
                      </div>
                    </div>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text v-if="!completedTasks">
              Do you want it? Put in the work!
            </v-card-text>

            <v-card-text
              v-if="completedTasks"
              class="ma-0 pa-0 ml-2"
            >
              <v-list class="pa-0 ma-0 text-xs">
                <v-list-item
                  v-for="(data,index) in completedTasks"
                  :key="data.slug"
                  :class="`d-flex align-center px-0 ${index > 0 ? 'mt-0':''}`"
                >
                  <v-icon
                    color="success"
                    size="20"
                  >
                    {{ icons.mdiCheckCircleOutline }}
                  </v-icon>
                  <router-link :to="{ path: data.link }">
                    <div class="d-flex align-center flex-grow-1 flex-wrap ml-2">
                      <div class="me-1">
                        <v-list-item-subtitle class="text-sm text-truncate">
                          <s>{{ data.desc }}</s>
                        </v-list-item-subtitle>
                      </div>
                      <v-spacer></v-spacer>
                    </div>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <Modal
      name="todoSnackBar"
      :modal="modal"
    ></Modal>
  </div>
</template>

<script>
import {
  mdiDotsVertical,
  mdiChevronUp,
  mdiChevronDown,
  mdiCheckCircleOutline,
} from '@mdi/js'
import Modal from '@/views/components/Modal.vue'
import dbFunctions from '@/db'

export default {
  components: {
    Modal,
  },
  props: {
    fighters: Array,
  },
  data() {
    return {
      todoLoaded: false,
      user: this.$store.state.user,
      team: {},
      leagues: false,
      training: {},
      prizes: {},
      modal: {
        show: false,
        title: '',
        content: '',
        data: {},
      },
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
        mdiCheckCircleOutline,
      },
    }
  },
  computed: {
    getTasks() {
      let teamLogo = false
      let teamAdvisor = false
      let teamName = false
      if (this.team) {
        teamLogo = this.team.logo
        if (this.team.advisor) {
          teamAdvisor = this.team.advisor.name
        }
        teamName = this.team.name
      }
      const tasks = [
        {
          icon: require('@/assets/images/buy/KombatCoinSilver.png'),
          slug: 'starter-pack-coins',
          desc: 'Claim Silver KombatCoin starter pack',
          done: this.user.profile?.onBoardered,
          link: '/onboard',
        },

        {
          icon: require('@/assets/images/avatars/FantasyFighterCard.png'),
          slug: 'starter-fighter-pack',
          desc: 'Claim fighter starter Pack',
          done: this.user.profile?.freeFighterPacks,
          link: '/onboard',
        },
        {
          icon: require('@/assets/images/misc/team.png'),
          slug: 'setup-team',
          desc: 'Setup team',
          done: (teamLogo && teamName),
          link: '/my-team',
        },
        {
          icon: require('@/assets/images/avatars/Accountant.png'),
          slug: 'hire-adviser',
          desc: 'Hire team advisor',
          done: teamAdvisor,
          link: '/advisor',
        },
        {
          icon: require('@/assets/images/avatars/KombatCoinDW.png'),
          slug: 'claim-prizes',
          desc: 'Claim prize winnings',
          done: this.prizes.length === 0,
          link: '/claim-winnings',
        },
        {
          icon: require('@/assets/images/avatars/FantasyFighterCard.png'),
          slug: 'recruit',
          desc: 'Recruit a fighters',
          done: this.fighters.length > 1,
          link: '/recruit-fighters',
        },
        {
          icon: require('@/assets/images/avatars/KombatCoinMain.png'),
          slug: 'join-league',
          desc: 'Join a league',
          done: this.team?.leagues?.length > 0,
          link: '/leagues',

        },
        {
          icon: require('@/assets/images/avatars/Analyst.png'),
          slug: 'team-training',
          desc: 'Setup team training',
          done: this.training,
          link: '/my-team',
        },
        {
          icon: require('@/assets/images/misc/t-logo.png'),
          slug: 'attend-event',
          desc: 'Enter tournament',
          link: '/fight-tournaments',
          done: this.user.profile?.eventsEntered || this.user.profile?.totalPoints,
        },
        {
          icon: require('@/assets/images/buy/dapper.png'),
          slug: 'connect-wallet',
          desc: 'Connect Dapper Account',
          done: this.user.profile?.dapperAddress,
          link: '/connect-wallet',
        },
      ]

      return tasks

      /*
         {
          icon: require('@/assets/images/misc/arcade-logo.png'),
          slug: 'play-arcade',
          desc: 'Play the "Arcade"',
          done: this.user.profile?.arcadePlayed > 0,
          link: '/fight-arcade',
        },

        {
          icon: require('@/assets/images/buy/KombatCoinGold.png'),
          slug: 'purchase-gold-coins',
          desc: 'Purchase Gold KombatCoins',
          done: this.user.profile.goldKombatCoins,
          link: '/connect-wallet',
        },
       */
    },
    toDoTasks() {
      const allTasks = this.getTasks.slice()
      const toDotasks = allTasks.filter(t => !t.done)

      return toDotasks
    },
    completedTasks() {
      const allTasks = this.getTasks.slice()
      const completedTasks = allTasks.filter(t => t.done)

      return completedTasks
    },
  },
  mounted() {
    this.getTeam()
    this.checkPrizes()
  },
  methods: {
    getTeam() {
      dbFunctions.getTeam(this.user.uid)
        .then(teamData => {
          this.team = teamData
          this.training = teamData?.training
        })
      this.todoLoaded = true
    },
    checkPrizes() {
      dbFunctions.getPrizeList(this.user.uid, false)
        .then(data => {
          this.prizes = data
          if (this.prizes.length > 0) {
            this.modal.show = true
            this.modal.title = 'You won a prize!'
            this.modal.content = '<div class="text-center"><a href="/claim-winnings" class="v-btn v-btn--outlined theme--dark success--text ma-3 pa-3">Claim Prize 💰</a></div>'
          }
          this.prizesLoaded = true
        })
    },
  },
}
</script>
<style>
#todo .v-list-item__subtitle.text-sm.text-truncate {
  width: 145px;
}

#todo .text-xs.v-tab {
  text-transform: none;
}

#todo .v-list-item__subtitle {
  font-size: 12px !important;
}

</style>

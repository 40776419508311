<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="3"
        class="mb-0 pb-0"
      >
        <ToDo
          :fighters="fighters"
        ></ToDo>
        <br>
        <LeaderBoard
          class="hidden-sm-and-down d-none"
        ></LeaderBoard>
      </v-col>

      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="9"
      >
        <UpcomingEvents :pass-table-rows="2"></UpcomingEvents>
        <br>
        <v-card>
          <router-link to="/recruit-fighters">
            <v-btn
              class="float-right mx-2 my-2 hidden-sm-and-down"
              outlined
              small
            >
              Recruit Fighters
            </v-btn>
          </router-link>
          <v-card-title>My Fighters</v-card-title>
          <TeamTable
            ref="teamTableRef"
            :fighters="fighters"
            :get-captain="captain"
            :loading-table="loadingTable"
          >
          </TeamTable>
          <div class="mx-auto text-center">
            <router-link :to="{ path: 'recruit-fighters' }">
              <v-btn
                class="mt-4 mb-8 hidden-sm-and-up position-relative justify-end table-recruit"
                small
                outlined
              >
                Recruit Fighters
              </v-btn>
              <br>
            </router-link>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LeaderBoard from './components/LeaderBoard.vue'
import ToDo from './components/ToDo.vue'
import UpcomingEvents from './components/UpcomingEvents.vue'
import TeamTable from './components/TeamTable.vue'
import dbFunctions from '@/db'

export default {
  components: {
    LeaderBoard,
    ToDo,
    UpcomingEvents,
    TeamTable,
  },
  data() {
    return {
      fighters: [],
      prizes: {},
      user: {},
      captain: {},
      loadingTable: true,

    }
  },
  mounted() {
    this.checkUser()
  },
  methods: {
    checkUser() {
      if (this.$store.state?.user) {
        this.user = this.$store.state.user
        if (window.$crisp) {
          window.$crisp?.push(['set', 'user:email', [this.user.email]])
          window.$crisp?.push(['set', 'user:nickname', [this.user.displayName]])
        }
      } else {
        this.user = {}
        this.user.isLoggedin = false
      }
      if (this.user.isLoggedin) {
        this.getFighters()
      }
    },
    async getFighters() {
      this.loadingTable = true
      await dbFunctions.getAllTeamFighters(this.user.uid)
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const fighter = doc.data()
            fighter.uid = doc.id
            this.fighters.push(fighter)
            this.loadingTable = false
          })
          this.loadingTable = false
        })
        .catch(error => {
          console.log(error)
          this.loadingTable = false
        })
    },

  },
}
</script>
